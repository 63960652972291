import './faq.scss';
import React from 'react';
import InnerLayout from './../InnerLayout/InnerLayout';
import { Grid } from '@material-ui/core';
import Box from './../Box/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserHeadset } from '@fortawesome/pro-solid-svg-icons';
import Accordion from './../Accordion/Accordion';
import { ContentElements } from '../../interfaces/ContentElements';
import CmsManager from '../../services/manager/CmsManager';

interface Props {}

interface FaqItem {
  title: string;
  content: string;
}
interface State {
  content?: ContentElements;
  faqGeneral?: ContentElements;
  faqGeneralItems: FaqItem[];
}

class Faq extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      faqGeneralItems: [],
    };
  }
  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned('faq-allgemein');
    Object.keys(content).forEach((item: string) => {
      if (
        item !== 'pageTitle' &&
        item !== 'pageDescription' &&
        item.endsWith('Title') &&
        typeof item === 'string'
      ) {
        const tempGeneralItems = this.state.faqGeneralItems;

        this.setState({
          faqGeneralItems: [
            ...tempGeneralItems,
            {
              title: content[item] as string,
              content: content[item.slice(0, -5)] as string,
            },
          ],
          content,
        });
      }
    });
  }

  render() {
    if (this.state.content) {
      return (
        <div>
          <InnerLayout className="faq-layout">
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Box
                  style={{
                    padding: '30px 90px 0 0',
                  }}
                >
                  <h2
                    className="titel"
                    dangerouslySetInnerHTML={{
                      __html: this.state.content.pageTitle as string,
                    }}
                  ></h2>
                  <div
                    className="faq-subtitel faq-text-class textWithColor"
                    dangerouslySetInnerHTML={{
                      __html: this.state.content.pageDescription as string,
                    }}
                  ></div>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  className="contact-box"
                  style={{
                    padding: '76px 0 0 14px',
                  }}
                >
                  <h2 className="titel" style={{ fontSize: '45px' }}>
                    <FontAwesomeIcon
                      icon={faUserHeadset}
                      style={{ marginRight: 10 }}
                    />
                    040 55566282
                  </h2>
                  <Box
                    className="faq-telephone-box"
                    style={{
                      padding: '0',
                    }}
                  >
                    <p className="faq-telephone faq-text-class">
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <p className="day">Montag bis Freitag</p>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <p className="time">8 bis 20 Uhr</p>
                        </Grid>
                      </Grid>
                    </p>
                    <p className="faq-telephone faq-text-class">
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                          <p className="day">Samstag</p>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <p className="time">8 bis 14 Uhr</p>
                        </Grid>
                      </Grid>
                    </p>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              className="direction-xs-column-reverse direction-lg-row"
            >
              <Grid item md={12}>
                <Box
                  className="faq-qq"
                  style={{
                    padding: '100px 100px 0 0',
                  }}
                >
                  <Accordion items={this.state.faqGeneralItems}></Accordion>
                </Box>
              </Grid>
            </Grid>
          </InnerLayout>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Faq;
