import "./card-protection-advantages.scss";
import InnerLayout from "../InnerLayout/InnerLayout";
import React from "react";
import { Grid } from "@material-ui/core";
import Box from "../Box/Box";
import { ContentElements } from "../../interfaces/ContentElements";

interface Props {
  content?: ContentElements;
}

class CardProtectionAdvantages extends React.Component<Props, {}> {
  render() {
    return (
      <>
        {this.props.content && (
          <div id="CardProtectionID">
            <InnerLayout className="card-protection-advantages-class">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Grid item xs={12} md={6}>
                    <Box
                      style={{
                        padding: "80px 14px 0 0",
                      }}
                    >
                      <div
                        className="advantages-subtitel advantages-text-class"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content
                            .cppkartendokumentenschutzSubtitle as string,
                        }}
                      ></div>
                    </Box>
                  </Grid>
                  <Box
                    style={{
                      padding: "10px 0 0",
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid item md={12} xs={12}>
                        <div
                          className="content text-class twoColors"
                          dangerouslySetInnerHTML={{
                            __html: this.props.content
                              .cppkartendokumentenschutz as string,
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid item md={7} xs={12}>
                    <Box
                      style={{
                        padding: "10px 0 0",
                      }}
                    >
                      <div
                        className="infos"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content
                            .cppkartendokumentenschutzinfos as string,
                        }}
                      ></div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </InnerLayout>
          </div>
        )}
      </>
    );
  }
}

export default CardProtectionAdvantages;
