import Imprint from './features/Imprint/Imprint';
import DataProtection from './features/DataProtection/DataProtection';
import AGB from './features/Agb/AGB';
import DocumentsAndFormulars from './features/DocumentsAndFormulars/DocumentsAndFormulars';

export interface Route {
  title: string;
  link?: string;
  component?: any;
  infoPage?: { route: string; content: any };
  private?: boolean;
}

export const navigationItems: Route[] = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Kartenschutz',
  },
  {
    title: 'FAQ',
    link: '/faq',
  },
  {
    title: 'Karten- & Dokumentenregistrierung',
    link: '/downloads',
  },
  {
    title: 'OWL',
  },
];
export const footerItems: Route[] = [
  {
    title: 'Karten- & Dokumentenregistrierung',
    link: '/downloads',
    component: DocumentsAndFormulars,
  },
  {
    title: 'AGB',
    link: '/agb',
    component: AGB,
  },
  {
    title: 'Datenschutz',
    link: '/datenschutz',
    component: DataProtection,
  },
  {
    title: 'Impressum',
    link: '/impressum',
    component: Imprint,
  },
];
