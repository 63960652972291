import { Grid } from '@material-ui/core';
import React from 'react';

import { ContentElements } from '../../interfaces/ContentElements';
import CmsManager from './../../services/manager/CmsManager';
import Box from './../../component/Box/Box';
import { Loading } from './../../component/Loading';
import InnerLayout from './../../component/InnerLayout/InnerLayout';

interface Props {}

interface State {
  loading: boolean;
  content?: ContentElements;
  otherContent?: ContentElements;
}

export default class AGB extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned('agb');

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <div className="Agb">
        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && (
          <InnerLayout className="agb">
            <Grid container spacing={2}>
              {this.state.content &&
                this.state.content.pageTitle &&
                this.state.content.pageDescription && (
                  <Grid item xs={12} lg={12}>
                    <Box>
                      {this.state.content && this.state.content.pageTitle && (
                        <h1>{this.state.content.pageTitle}</h1>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.content.pageDescription as string,
                        }}
                      ></div>
                    </Box>
                  </Grid>
                )}
            </Grid>
          </InnerLayout>
        )}
      </div>
    );
  }
}
