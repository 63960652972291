import './footer.scss';
import InnerLayout from '../InnerLayout/InnerLayout';
import React from 'react';
import { Container } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

export default function Footer(props: { items: any[] }) {
  return (
    <div>
      <InnerLayout className="footer-class">
        <Container>
          <nav>
            {props.items.map((item, index) => {
              if (item.link.indexOf('http') === -1) {
                return (
                  <NavLink
                    key={'footer-nav-' + index}
                    activeClassName="active"
                    to={item.link}
                  >
                    {item.title}
                  </NavLink>
                );
              }

              if (item.link.indexOf('http') === 0) {
                return (
                  <a
                    target="_blank"
                    key={'footer-nav-' + index}
                    href={item.link}
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </a>
                );
              }

              return false;
            })}
          </nav>
        </Container>
      </InnerLayout>
    </div>
  );
}
