import React from "react";
import CardProtection from "../../component/CardProtection/card-protection";
import HowWorkCpp from "../../component/HowWorkCPPCard/how-work-cpp";
import CardProtectionAdvantages from "../../component/CardProtectionAdvantages/card-protection-advantages";
import { ContentElements } from "../../interfaces/ContentElements";
import CmsManager from "../../services/manager/CmsManager";

interface State {
  content?: ContentElements;
}

class Home extends React.Component<{}, State> {
  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned("dashboard");

    this.setState({
      content,
    });
  }

  render() {
    return (
      <>
        {this.state && this.state.content && (
          <>
            <CardProtection content={this.state.content} />

            <CardProtectionAdvantages content={this.state.content} />

            <HowWorkCpp content={this.state.content} />
          </>
        )}
      </>
    );
  }
}

export default Home;
