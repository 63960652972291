import './App.scss';
import * as React from 'react';
import Header from './component/Header/header';
import { BrowserRouter, Route, Switch, NavLink } from 'react-router-dom';
import Footer from './component/Footer/footer';
import Faq from './component/FAQ/faq';
import Home from './features/Home/Home';
import ScrollToTop from './component/ScrollToTop';
import { Divider, Drawer, List, ListItem } from '@material-ui/core';
import { NavItem } from 'react-bootstrap';
import {
  navigationItems,
  footerItems,
  Route as RouteInterface,
} from './routes';
import Payment from './component/Payment/payment';

interface State {
  isDrawerOpen: boolean;
  content: any;
  location: string;
}

class App extends React.Component<{}, State> {
  public navigation = navigationItems;
  public footer = footerItems;

  constructor(props: any) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      content: '',
      location: '',
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
  }

  toggleDrawer() {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  }

  closeDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  scrollToElement = (e: any, target: string) => {
    e.preventDefault();

    const element = document.getElementById(target)?.getBoundingClientRect();

    if (element) {
      window.scrollTo({
        top: element ? element.top + window.scrollY : 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  componentDidMount() {
    this.setState({
      location: window.location.pathname,
    });
  }

  getLocation = () => {
    window.setTimeout(() => {
      this.setState({
        location: window.location.pathname,
      });
    }, 200);
  };

  render() {
    return (
      <div>
        <div className="App">
          <BrowserRouter basename="/">
            <ScrollToTop />

            <div className="layout">
              <Drawer
                onBackdropClick={this.closeDrawer}
                anchor="right"
                open={this.state.isDrawerOpen}
              >
                <List>
                  <ListItem button>
                    <NavLink
                      onClick={() => this.getLocation()}
                      className="home"
                      to={'/'}
                    >
                      Startseite
                    </NavLink>
                  </ListItem>

                  <ListItem button>
                    {this.state.location && this.state.location !== '/faq' && (
                      <NavItem
                        className="cpID"
                        onClick={() => {
                          this.getLocation();
                        }}
                      >
                        <div
                          className="card-protection"
                          onClick={(e: any) =>
                            this.scrollToElement(e, 'CardProtectionID')
                          }
                        >
                          Kartenschutz
                        </div>
                      </NavItem>
                    )}

                    {this.state.location && this.state.location === '/faq' && (
                      <NavLink to={'/'} className="cpID">
                        <div
                          onClick={(e: any) =>
                            window.setTimeout(() => {
                              this.scrollToElement(e, 'CardProtectionID');
                            }, 400)
                          }
                        >
                          Kartenschutz
                        </div>
                      </NavLink>
                    )}
                  </ListItem>

                  <ListItem button>
                    <NavLink
                      onClick={() => this.getLocation()}
                      className="documents"
                      to="/downloads"
                    >
                      Karten- & Dokumentenregistrierung
                    </NavLink>
                  </ListItem>

                  <ListItem button>
                    <NavLink
                      onClick={() => this.getLocation()}
                      className="faq"
                      to="/faq"
                    >
                      FAQ
                    </NavLink>
                  </ListItem>

                  <ListItem button>
                    <NavItem className="owl">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.owldetect.de"
                      >
                        OWL
                      </a>
                    </NavItem>
                  </ListItem>

                  <Divider />
                </List>
              </Drawer>
            </div>

            <Header items={this.navigation} toggleDrawer={this.toggleDrawer} />

            <Switch>
              <Route exact path="/" component={Home} />

              {[...navigationItems, ...footerItems].map(
                (item: RouteInterface, index) => {
                  if (item.component) {
                    const attr = {
                      exact: true,
                      key: 'route-' + index,
                      path: item.link,
                      component: item.component,
                    };
                    return <Route {...attr}></Route>;
                  }
                }
              )}

              <Route exact path="/faq" component={Faq} />
              <Route exact path="/payment" component={Payment} />
            </Switch>

            <Footer items={this.footer} />
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default App;
