import './DocumentsAndFormulars.scss';

import { Grid, Hidden } from '@material-ui/core';
import React from 'react';

import InnerLayout from '../../component/InnerLayout/InnerLayout';
import { Loading } from '../../component/Loading';
import CmsManager from './../../services/manager/CmsManager';

interface State {
  content?: any;
  loading: boolean;
}

export default class DocumentsAndFormulars extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned('downloads');

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <InnerLayout>
        {this.state.loading && <Loading />}

        {!this.state.loading && (
          <div className="DocumentsAndFormulars">
            <div
              style={{
                marginTop: 101,
                zIndex: 1,
                position: 'relative',
              }}
            >
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  {this.state.content?.pageTitle && (
                    <>
                      <Hidden lgUp>
                        <h2
                          className="titel"
                          style={{
                            fontWeight: 'normal',
                            marginTop: 25,
                            marginBottom: 35,
                            color: 'black',
                          }}
                        >
                          {this.state.content?.pageTitle || ''}{' '}
                        </h2>
                      </Hidden>

                      <Hidden mdDown>
                        <h2
                          className="titel"
                          style={{
                            fontWeight: 'normal',
                            marginTop: 75,
                            marginBottom: 35,
                            color: 'black',
                          }}
                        >
                          {this.state.content?.pageTitle || ''}{' '}
                        </h2>
                      </Hidden>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={2}>
              {this.state.content &&
                Object.keys(this.state.content).map((item: string, index) => {
                  if (
                    this.state.content &&
                    item !== 'pageTitle' &&
                    item !== 'pageDescription' &&
                    item.endsWith('Title')
                  ) {
                    return (
                      <Grid item xs={12} key={index}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.content[
                              item.slice(0, -5)
                            ] as string,
                          }}
                        ></p>
                      </Grid>
                    );
                  } else {
                    return '';
                  }
                })}
            </Grid>
          </div>
        )}
      </InnerLayout>
    );
  }
}
