import "./how-work-cpp.scss";
import InnerLayout from "../InnerLayout/InnerLayout";
import React from "react";
import { Grid } from "@material-ui/core";
import Box from "../Box/Box";
import { faShieldCheck } from "@fortawesome/pro-light-svg-icons";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CmsManager from "../../services/manager/CmsManager";
import { ContentElements } from "../../interfaces/ContentElements";

interface Props {
  content?: ContentElements;
}

class HowWorkCpp extends React.Component<Props, {}> {
  render() {
    return (
      <>
        {this.props.content && (
          <div>
            <InnerLayout className="how-work-cpp-class">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box
                    className="how-work-cpp-box"
                    style={{
                      padding: "30px 14px 0 0",
                      display: "grid",
                    }}
                  >
                    <h2
                      className="how-work-cpp-titel textWithColor"
                      dangerouslySetInnerHTML={{
                        __html: this.props.content
                          .kartenschutzfunktionTitle as string,
                      }}
                    ></h2>
                    <div
                      className="how-work-cpp-subtitel advantages-text-class"
                      dangerouslySetInnerHTML={{
                        __html: this.props.content
                          .kartenschutzfunktionSubtitle as string,
                      }}
                    ></div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={6}
                  xs={12}
                  style={{ marginLeft: "auto" }}
                  className="grid-item"
                >
                  <div className="register-data">
                    <h2 className="textWithColor-HWC">
                      <FontAwesomeIcon
                        icon={faWpforms}
                        style={{ marginRight: 10 }}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.props.content
                            .datenregistrierenTitle as string,
                        }}
                      ></span>{" "}
                    </h2>
                    <div
                      className="how-work-cpp-container textWithOpacity"
                      dangerouslySetInnerHTML={{
                        __html: this.props.content.datenregistrieren as string,
                      }}
                    ></div>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  style={{ marginRight: "auto" }}
                  className="grid-item"
                >
                  <div className="be-sure">
                    <h2 className="textWithColor-HWC">
                      <FontAwesomeIcon
                        icon={faShieldCheck}
                        style={{ marginRight: 10 }}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.props.content.sicherseinTitle as string,
                        }}
                      ></span>{" "}
                    </h2>
                    <div
                      className="how-work-cpp-container"
                      dangerouslySetInnerHTML={{
                        __html: this.props.content.sichersein as string,
                      }}
                    ></div>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Box
                    className="how-work-cpp-box2"
                    style={{
                      padding: "30px 14px 0 0",
                      display: "grid",
                    }}
                  >
                    <div
                      className="how-work-cpp-success advantages-text-class"
                      dangerouslySetInnerHTML={{
                        __html: this.props.content
                          .kartenschutzfunktion as string,
                      }}
                    ></div>
                  </Box>
                </Grid>
              </Grid>
            </InnerLayout>
          </div>
        )}
      </>
    );
  }
}

export default HowWorkCpp;
