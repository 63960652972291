import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import { Icon } from "@material-ui/core";
import "./Accordion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";

const ExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    marginBottom: "10px",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: "10px",
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export default function Accordion({ items }: any) {
  const [expanded, setExpanded] = React.useState<string | false>("x");

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {items.map((item: any, index: number) => (
        <ExpansionPanel
          square
          expanded={expanded === "panel" + index}
          onChange={handleChange("panel" + index)}
          key={"panel-" + index}
        >
          <ExpansionPanelSummary
            aria-controls={"panel" + index + "d-content"}
            id={"panel" + index + "d-header"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                paddingRight: 30,
              }}
            >
              <Typography
                variant="h6"
                style={{
                  position: "relative",
                  color: "#790072",
                  marginLeft: "30px",
                }}
                dangerouslySetInnerHTML={{ __html: item.title }}
              ></Typography>

              <Icon className="add">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    width: "15px",
                  }}
                />
              </Icon>

              <Icon className="remove">
                <FontAwesomeIcon
                  icon={faChevronUp}
                  style={{
                    width: "15px",
                  }}
                />
              </Icon>
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails
            style={{
              paddingLeft: 30,
            }}
            dangerouslySetInnerHTML={{ __html: item.content }}
          ></ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  );
}
