import "./card-protection.scss";
import InnerLayout from "../InnerLayout/InnerLayout";
import React from "react";
import { Grid } from "@material-ui/core";
import Box from "../Box/Box";
import { ContentElements } from "../../interfaces/ContentElements";

interface Props {
  content?: ContentElements;
}

class CardProtection extends React.Component<Props, {}> {
  render() {
    return (
      <>
        {this.props.content && (
          <div>
            <div className="cpp-titel-img">
              <img src="/assets/images/heroes/start.jpg" alt="CPPTITEL" />
            </div>

            <InnerLayout>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Grid item xs={12} md={6}>
                    <Box
                      style={{
                        height: "100%",
                        padding: "30px 14px 0 0",
                      }}
                    >
                      <h2
                        className="titel"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content
                            .cppkartenschutzTitle as string,
                        }}
                      ></h2>

                      <p
                        className="subtitel text-class"
                        dangerouslySetInnerHTML={{
                          __html: this.props.content
                            .cppkartenschutzSubtitle as string,
                        }}
                      ></p>
                    </Box>
                  </Grid>

                  <Box
                    style={{
                      padding: "10px 0 0",
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid item md={6} xs={12}>
                        <div
                          className="content text-class twoColors"
                          dangerouslySetInnerHTML={{
                            __html: this.props.content
                              .cppkartenschutz as string,
                          }}
                        ></div>
                      </Grid>

                      <Grid item md={6} xs={12} style={{}}>
                        <div
                          className="content text-class"
                          dangerouslySetInnerHTML={{
                            __html: this.props.content
                              .cppkartenschutz2 as string,
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </InnerLayout>
          </div>
        )}
      </>
    );
  }
}

export default CardProtection;
