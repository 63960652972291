import './header.scss';
import { NavItem } from 'react-bootstrap';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Hidden, Container } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CmsManager from '../../services/manager/CmsManager';
import { ContentElements } from '../../interfaces/ContentElements';

interface Props {
  toggleDrawer: any;
  items: any[];
}

interface State {
  location: string;
  content?: ContentElements;
}
class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      location: '',
    };
  }

  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned('dashboard');
    this.setState({
      location: window.location.pathname,
      content,
    });
  }

  scrollToElement = (e: any, target: string) => {
    e.preventDefault();
    const element = document.getElementById(target)?.getBoundingClientRect();

    if (element) {
      window.scrollTo({
        top: element ? element.top + window.scrollY : 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  openLoginDialog() {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      html: this.state.content?.dialogvalue || '',
      confirmButtonText: 'Schließen',
      focusConfirm: false,
    });
  }

  getLocation = () => {
    window.setTimeout(() => {
      this.setState({
        location: window.location.pathname,
      });
    }, 200);
  };

  render() {
    return (
      <div>
        <div className="navigation">
          <Container maxWidth="lg">
            <div className="nav-wrapper">
              <div className="nav-item logo">
                <NavLink to={'/'} onClick={() => this.getLocation()}>
                  <img
                    src="/assets/images/cpp-kartenschutz.png"
                    alt="Logo der Cpp"
                  />
                </NavLink>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="burger">
                  <Button onClick={() => this.props.toggleDrawer()}>
                    <FontAwesomeIcon icon={faBars} />
                  </Button>
                </div>
                <div className="nav-item nav-item-list">
                  <nav>
                    <Hidden mdDown>
                      {this.state.location && this.state.location !== '/faq' && (
                        <NavItem
                          onClick={() => {
                            this.getLocation();
                          }}
                        >
                          <div
                            className="card-protection"
                            onClick={(e: any) => {
                              this.scrollToElement(e, 'CardProtectionID');
                            }}
                          >
                            Kartenschutz
                          </div>
                        </NavItem>
                      )}
                      {this.state.location && this.state.location === '/faq' && (
                        <NavLink to={'/'}>
                          <div
                            className="card-protection"
                            onClick={(e: any) => {
                              window.setTimeout(() => {
                                this.scrollToElement(e, 'CardProtectionID');
                              }, 400);
                            }}
                          >
                            Kartenschutz
                          </div>
                        </NavLink>
                      )}

                      <NavLink
                        onClick={() => this.getLocation()}
                        className="documents"
                        to="/downloads"
                      >
                        Karten- & Dokumentenregistrierung
                      </NavLink>

                      <NavLink
                        onClick={() => this.getLocation()}
                        className="faq"
                        to="/faq"
                      >
                        FAQ
                      </NavLink>

                      <NavItem className="owl">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://app.owldetect.de"
                        >
                          OWL
                        </a>
                      </NavItem>
                    </Hidden>
                    {/*  CPPB-129 - Temporäres Ausblenden vom Login Button
                    <NavItem>
                      <div
                        className="login"
                        onClick={() => {
                          this.openLoginDialog();
                        }}
                        id="loginButton"
                      >
                        Login
                      </div>
                    </NavItem>
                    */}
                  </nav>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default Header;
