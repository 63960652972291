import './payment.scss';
import React from 'react';
import InnerLayout from './../InnerLayout/InnerLayout';
import { Grid, TextField } from '@material-ui/core';
import Box from './../Box/Box';
import { API } from './../../services/API';
import { Alert } from './../../services/alert';
import CmsManager from '../../services/manager/CmsManager';
import { ContentElements } from '../../interfaces/ContentElements';

declare global {
  interface Window {
    Novalnet: any;
  }
}
interface State {
  isVisable: boolean;
  errors: any;
  customerNo: string;
  content?: ContentElements;
}

class Payment extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      isVisable: false,
      errors: {},
      customerNo: '',
    };
    this.updateNovalNetScript = this.updateNovalNetScript.bind(this);
  }

  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned('payment');
    this.setState({
      content,
    });
    console.log(this.state.content);

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://paygate.novalnet.de/v2/checkout.js?t=${Date.now()}`;
    script.id = 'novalnet-checkout-js';
    document.body.appendChild(script);
  }

  validate() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = ['customerNo'];

    fields.forEach((field: string) => {
      if (
        !state[field] ||
        (typeof state[field] === 'string' && state[field].trim() === '')
      )
        errors[field] = 'Dieses Feld muss ausgefüllt werden';
    });

    this.setState({
      errors,
    });
    return JSON.stringify(errors) === '{}';
  }

  handleChange(field: any) {
    return (e: any) => {
      this.setState({
        [field]: e.target.value,
      } as any);
    };
  }

  async novalnetRender(id: string) {
    if (!this.validate()) return;
    //TODO: variable url
    const result = await API.post(
      `https://testapi.mehrwerk.de:443/v2/accounting/billing/${this.state.customerNo}/paymentProblem/`,
      {}
    );
    if (!result || result === 404 || result === 500) {
      //TODO: error handling
      Alert.error(
        this.state.content?.errortitleTitle as string,
        this.state.content?.errortitle as string
      );
    } else {
      this.updateNovalNetScript(result.txn_secret);
      window.Novalnet.render(id);
      this.setState({
        isVisable: true,
      });
    }
  }

  updateNovalNetScript(txnSecret: string) {
    const novalnetScript = document.createElement('script');
    novalnetScript.id = 'novalnet-script';
    novalnetScript.text = `Novalnet.setParam("nn_it", "inline");\n Novalnet.setParam("txn_secret","${txnSecret}");`;

    document.body.appendChild(novalnetScript);
  }

  render() {
    const errors = this.state.errors as any;
    if (this.state.content) {
      return (
        <div>
          <InnerLayout className="payment-layout">
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <div
                  style={{
                    visibility: !this.state.isVisable ? 'visible' : 'hidden',
                  }}
                  className="content text-class"
                  dangerouslySetInnerHTML={{
                    __html: this.state.content.paymentcontent as string,
                  }}
                ></div>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  style={{
                    padding: '30px 14px 0 0',
                  }}
                >
                  <TextField
                    style={{
                      visibility: !this.state.isVisable ? 'visible' : 'hidden',
                    }}
                    autoFocus
                    label={this.state.content.kundennrTitle}
                    type="text"
                    margin="dense"
                    value={this.state.customerNo}
                    onChange={this.handleChange('customerNo')}
                    error={errors.customerNo !== undefined}
                    helperText={errors.customerNo ? errors.customerNo : ''}
                    InputProps={{
                      className: 'text-field-input',
                    }}
                  />
                  <button
                    className="pay-now"
                    type="submit"
                    onClick={() => this.novalnetRender('novalnet_payment_form')}
                    style={{
                      visibility: !this.state.isVisable ? 'visible' : 'hidden',
                    }}
                  >
                    <span>Pay Now</span>
                  </button>
                  <div id="novalnet_payment_form"></div>
                </Box>
              </Grid>
            </Grid>
          </InnerLayout>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Payment;
