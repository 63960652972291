import { API } from './API';

class Auth {
  static async login(
    username: string,
    password: string
  ): Promise<true | string> {
    try {
      const result = await API.post('auth/login', {
        username,
        password,
      });

      const token = result.access_token;

      localStorage.setItem('token', token);
      API.setToken(token);

      window.location.href =
        window.location.pathname === '/mehrwerk-login'
          ? '/mein-konto'
          : window.location.href;

      return true;
    } catch (e) {
      const error: any = e;
      if (error.message.indexOf('User is not verified') > -1)
        return 'notVerified';
      return 'invalidCredentials';
    }
  }

  static async passAuthTokenFromMlpToIam(token: string) {
    try {
      const result = await API.post('auth/token', {
        token: token,
      });

      const newToken = result.access_token;

      localStorage.setItem('token', newToken);
      API.setToken(newToken);

      return true;
    } catch (e) {
      const error: any = e;
      if (error.message.indexOf('User is not verified') > -1)
        return 'notVerified';

      return 'invalidCredentials';
    }
  }

  static logout() {
    localStorage.removeItem('token');
    API.setToken();
    //(window as any).location.href = '/';
  }

  static async checkLogin(): Promise<boolean> {
    const storedToken = localStorage.getItem('token');

    if (!API.token && storedToken) API.token = storedToken;

    return this.isLoggedIn();
  }

  static isLoggedIn(): boolean {
    return !!API.token;
  }

  static getTokenInfo() {
    if (!API.token) return null;

    const parts = API.token.split('.');
    return JSON.parse(atob(parts[1]));
  }

  static getFKN() {
    const tokenInfo = Auth.getTokenInfo();

    if (!tokenInfo) return null;

    if (tokenInfo.contracts && tokenInfo.contracts.length > 0) {
      return tokenInfo.contracts[0].id;
    } else if (tokenInfo.userInfo && tokenInfo.userInfo._aditoBasicContractId) {
      return tokenInfo.userInfo._aditoBasicContractId;
    }

    return null;
  }
}

export default Auth;
